@import '~theme/variables';

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-width: 23.875rem;
  padding: 0 1rem;
  margin: 2% auto 4.5rem;
}

.logoContainer {
  max-width: 10.5rem;
}

.header {
  font-family: $font-family-bold;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2.375rem;
  margin: 0;
  color: $on-surface-high-emphasis;
  margin-top: 2rem;
  text-align: center;
}

.imageContainer {
  margin: 2.5rem 0;
}

.img {
  height: auto;
  min-width: 7.5rem;
  max-width: 12.5rem;
}

.p {
  font-family: $font-family;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.125rem;
  text-align: center;
  color: $on-surface-high-emphasis;
  margin: 0 0 1.5rem;
}

.fullWidth {
  width: 100%;
}

.primaryButtonContainer {
  @extend .fullWidth;
  margin: 0.75rem 0 0;
}

.orTextContainer {
  margin: 3.25rem 0 0;
}

.orTextGeneric {
  font-family: $font-family;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  color: $on-surface-high-emphasis;
  position: relative;
  margin: 0 0 1.5rem;
}

.orText {
  @extend .orTextGeneric;
  text-align: center;
}

.orTextWithLines {
  @extend .orTextGeneric;

  &::before {
    content: '';
    color: $grayscale-5;
    width: 2.2rem;
    border-bottom: 0.125rem solid $grayscale-5;
    position: absolute;
    left: -2.8rem;
    top: 50%;
    height: 0.125rem;
  }

  &::after {
    content: '';
    color: $grayscale-5;
    width: 2.2rem;
    border-bottom: 0.125rem solid $grayscale-5;
    position: absolute;
    right: -2.8rem;
    top: 50%;
  }
}

.waringIcon {
  color: $complementary-danger;
  width: 1.25rem;
  height: 1.25rem;
}

.registerLink {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: $primary-base;
  font-weight: 500;
  text-decoration: none;
  margin: 1.125rem 0 0;
}

.barCodeIcon {
  width: 2.1875rem;
  height: 1.8125rem;
  cursor: pointer;
  align-self: center;
  color: $grayscale-1;
}
