@import '~theme/variables';

:export {
  brandFontFamily: $font-family;
}

.container {
  width: 90%;
  margin: auto;
  max-width: 80rem;
}

.section {
  padding: 2.5rem 0;
  display: block;
  background-color: $surfaces-background;
}

.row {
  display: flex;
  flex-wrap: nowrap;
  margin: 0 -1rem;
}

@media (min-width: 75rem) {
  .container {
    max-width: 71.25rem;
  }
}

@media (max-width: 75rem) and (min-width: 64rem) {
  .container {
    max-width: 100%;
  }
}

@media (max-width: 64rem) and (min-width: 48rem) {
  .container {
    max-width: 100%;
  }
  .row {
    display: block;
    flex-wrap: nowrap;
  }
}

@media (max-width: 48rem) and (min-width: 36rem) {
  .container {
    max-width: 100%;
  }
  .row {
    display: block;
    flex-wrap: nowrap;
  }
}

@media (max-width: 576px) {
  .container {
    max-width: 100%;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
  }
}
