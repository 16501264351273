@import '~theme/variables';

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-width: 23.875rem;
  margin: auto;
}

.logoContainer {
  max-width: 8.5rem;
}

.header {
  font-family: $font-family-bold;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2.375rem;
  margin: 0;
  color: $on-surface-high-emphasis;
}

.imageContainer {
  margin: 2.5rem 0;
}

.img {
  height: auto;
  min-width: 7.5rem;
  max-width: 12.5rem;
}

.p {
  font-family: $font-family;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.125rem;
  text-align: center;
  color: $on-surface-high-emphasis;
  margin: 0 0 2.25rem;
}

.form {
  width: 100%;
  max-width: 21.25rem;
}

.primaryButtonContainer {
  width: 100%;
  margin: 2.75rem 0 3.75rem;
}

.footerText {
  font-size: 0.875rem;
  color: $grayscale-1;
  line-height: 1.125rem;
  margin: 0;
}

.footerButton {
  outline: 0;
  border: 0;
  padding: 0 0 0 0.375rem;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: $secondary-button-text;
  cursor: pointer;
  font-family: $font-family-bold;
  font-weight: 700;
}

.flexCenter {
  display: flex;
  align-items: center;
}

.otpBox {
  max-width: 3.125rem;
  max-height: 3.125rem;
  margin: 0 0.25rem;

  input {
    text-align: center;
  }
}

.otpContainer {
  @extend .flexCenter;
  justify-content: center;
}

.otpInput {
  width: 3rem !important;
  height: 3rem;
  border-radius: 0.5rem;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  border: 0;
  background-color: $surfaces-surface;
  -webkit-appearance: none;
}

.separator {
  width: 0.5rem;
}

.errorMsg {
  color: $complementary-danger;
  font-size: 0.75rem;
  margin: 1rem 0;
}

.resendOtpMessage {
  font-family: $font-family;
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.otpError {
  border: 1px solid $complementary-danger;
}

.resendOtpErrorMessage {
  @extend .resendOtpMessage;
  color: $complementary-danger;
}

.resendOtpSuccessMessage {
  @extend .resendOtpMessage;
  color: $complementary-success;
}

.resendIcon {
  margin-right: 0.5rem;
}
